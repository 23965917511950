import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import MetaData from '../components/MetaData';
import Layout from '../components/layout/Layout';

const PrivacyPolicyPage = () => {
  return (
    <>
      <MetaData
        title='Privacy Policy'
        description='Your privacy matters at Nexton. We ensure the confidentiality of any information collected from you on our website and other platforms.'
      />
      <Layout selected='privacy-policy'>
        <Flex justifyContent='center' alignItems='center' flexWrap='wrap' flexDirection='column' mx='13px' my='50px'>
          <Box maxWidth='1024px'>
            <Heading color='nextonBlue' fontSize={[4, 5, 6]} fontWeight='500' textAlign='left' mb={4} as='h1'>
              Data Privacy Policy for Candidates and Visitors
            </Heading>
            <Text fontSize={[2, 2, 3]} lineHeight='25px' textAlign='justify' mt={3}>
              <div className='body-post'>
                <br />
                <p>Last Updated September 11, 2023</p>
                <Text fontSize={[4, 4, 4]} mt={4}>
                  <strong>1. Introduction</strong>
                </Text>
                <p>
                  Here at Nexton, <strong>we take privacy very seriously</strong> and are{' '}
                  <strong>committed to protecting all the information</strong> you share with us.
                </p>
                <p>
                  Within this Privacy Policy for Candidates (the <strong>“Privacy Policy”</strong>), you will find:
                </p>
                <ul>
                  <li>What information we collect and how we do it</li>
                  <li>How we use the information</li>
                  <li>What rights you have in relation to this policy</li>
                </ul>
                <p>
                  <strong>Please, read it carefully</strong>. If there are any terms in this Privacy Policy that you do
                  not agree with, or if you have any questions or concerns about our policy or our practices with
                  regards to your personal information, please contact our Privacy Officer at privacy@nextonlabs.com.
                </p>
                <p>
                  This Privacy Policy applies to all information collected through our website, social media, products,
                  services, sales, marketing or events (we refer to them collectively in this Privacy Policy as the "
                  <strong>Services</strong>").
                </p>
                <p>
                  For the purpose of this Privacy Policy, any and all individuals who are or have been part of a
                  selection process by Nexton in connection to any of our clients, are referred to as “Candidates”. Any
                  other person that has visited our website, social media, marketing campaigns and/or participated in
                  any way of our events, is referred to as “Visitor”. This Privacy Policy applies to both Candidates and
                  Visitors.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>2. Collection of Personal Information</strong>
                </Text>
                <p style={{ 'padding-left': ' 10px' }}>
                  <strong>a. Personal Information voluntarily disclosed</strong>
                </p>
                <p style={{ 'padding-left': ' 30px' }}>
                  We collect the personal information you provide to us such as name, personal identification number,
                  date of birth, full address, contact information, email address, work experience, education, social
                  media accounts and any other personal information you may provide to us (the “
                  <strong>Personal Information</strong>”).
                </p>
                <p style={{ 'padding-left': ' 30px' }}>
                  All Personal Information that you provide to us must be true, complete and accurate, and you must
                  notify us of any changes to such personal information.
                </p>
                <p style={{ 'padding-left': ' 10px' }}>
                  <strong>b. Personal Information publicly displayed</strong>
                </p>
                <p style={{ 'padding-left': ' 30px' }}>
                  We may collect certain Personal Information that you publicly display (for instance, through social
                  media platforms such as LinkedIn, Instagram, Facebook, and other social media platforms that exist or
                  will come to existence in the future). This information might also be collected automatically,
                  provided that you have granted public access to the relevant social media.
                </p>
                <p style={{ 'padding-left': ' 10px' }}>
                  <strong>c. Information from Minors</strong>
                </p>
                <p style={{ 'padding-left': ' 30px' }}>
                  <strong>We do not knowingly solicit data from or market to children under 18 years of age</strong>. By
                  using the Website, you represent that you are at least 18 or that you are the parent or guardian of
                  such a minor and consent to such minor dependent’s use of the Website. If we learn that personal
                  information from users less than 18 years of age has been collected, we will take reasonable measures
                  to promptly delete such data from our records. If you become aware of any data we may have collected
                  from children under age 18, please contact us at privacy@nextonlabs.com.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>3. Use of Personal Information</strong>
                </Text>
                <p>
                  We may use Personal Information to create, operate and improve our Services; to communicate with you;
                  to match job openings with your profile, to customize content for Candidates and Visitors, and to
                  improve our website.
                </p>
                <p>
                  In all cases, <strong>your Personal Information is treated as confidential</strong>, securely stored
                  and accessible to authorized personnel only.
                </p>
                <p>
                  We use Personal Information, either voluntarily disclosed or publicly displayed, for a variety of
                  business purposes described below:
                </p>
                <ul>
                  <li>Legitimate business interests ("Business Purposes");</li>
                  <li>In order to enter into or perform a contract with you ("Contractual Reasons");</li>
                  <li>For compliance with our legal obligations ("Legal Reasons").</li>
                </ul>
                <p>In particular, we use the information we collect for the following purposes:</p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Job Openings.</strong> We may use your Personal Information to find and present to
                  you job opportunities that may fit your profile. Accordingly, we may use your Personal Information to
                  present your profile to our clients in relation to a job opportunity.
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Marketing.</strong> We use your full name, basic contact data and geographic
                  location to send you marketing and promotional communications. You can opt-out of our marketing emails
                  at any time (see the "Your Privacy Rights" information below).
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Targeted Advertising.</strong> We use your basic contact data, e-mail address and
                  location to deliver you targeted advertising. We may work with third parties to develop and display
                  content and advertising tailored to your interests and/or location and to measure its effectiveness.
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Enforcement.</strong> We use your full name and basic contact data, national
                  identifiers, automatically collected data and information collected from other sources to enforce our
                  terms, conditions and policies, for Business Purposes, Legal Reasons and/or in some cases for
                  Contractual Reasons.
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Subpoenas and Other Legal Requests.</strong> We use your full name and basic
                  contact data, national identifiers, automatically collected data and information collected from other
                  sources to respond to legal requests and prevent harm, for Legal Reasons. For example, if we receive a
                  subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
                  In general, we can use your information to resolve disputes, charge fees and solve problems.
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Internal Business Purposes.</strong> We use your full name and basic contact data,
                  behavior data and other automatically collected data and information collected from other sources for
                  other Business Purposes, such as data analysis, identifying usage trends, determining the
                  effectiveness of our promotional campaigns and to evaluate and improve our Services, our marketing and
                  enhancing your user experience. We also use the information for quality control and staff training.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>4. Cookies</strong>
                </Text>
                <p>
                  We use cookies and other tracking technologies on our website. They ensure a consistent and efficient
                  experience for visitors, and perform essential functions.
                </p>
                <p>
                  Cookies are small pieces of data, stored in text files, and on your computer or other device when
                  websites are loaded in a browser. Both websites and HTML emails may also contain other tracking
                  technologies such as “web beacons” or “pixels.” These are typically small transparent images that
                  provide us with statistics, for similar purposes as cookies.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>5. Share of Personal Information</strong>
                </Text>
                <p>
                  We share information to comply with laws, to protect your rights, and to fulfill business obligations.
                  We take care of allowing your Personal Information to be accessed only by those who reasonably need it
                  to perform their tasks and duties, and sharing it with third parties who have a legitimate purpose to
                  access it.
                </p>
                <p>
                  We may also disclose your information where we are legally required to do so in order to comply with
                  applicable law, governmental requests, a judicial proceeding, court order or legal process, such as in
                  response to a court order or a subpoena (including in response to public authorities to meet national
                  security or law enforcement requirements).
                </p>
                <p>
                  Finally, we may share your data with third party vendors, service providers, contractors or agents who
                  perform services for us or on our behalf and require access to such information to do that work.{' '}
                  <br />
                  We may disclose your personal information for any other purpose with your express Consent.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>6. Retention and Deletion</strong>
                </Text>
                <p>
                  We will only keep your personal information for as long as it is necessary for the purposes set out in
                  this Privacy Policy, unless a longer retention period is required or permitted by law (such as tax,
                  accounting or other legal requirements).
                </p>
                <p>
                  The criteria used to determine the period for which personal information about you will be retained
                  varies depending on the legal basis under which we process the personal information:
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Consent.</strong> Where we are processing personal information based on your
                  Consent, we generally will retain the information for the period of time necessary to fulfill the
                  underlying agreement with you.
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Business Purposes.</strong> Where we are processing your personal information for
                  our Business Purposes, we generally will retain such information for a reasonable period of time based
                  on the particular interest.
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;<strong>Legal Reasons.</strong> Where we are processing personal information based on a
                  legal obligation, we generally will retain the information for the period of time necessary to fulfill
                  the legal obligation.
                </p>
                <p>
                  Except in the case of Personal information being stored for Legal Reasons,{' '}
                  <strong>your Personal Information will be deleted upon your request</strong>.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>7. Protection of Personal Information</strong>
                </Text>
                <p>
                  Nexton is an international company and our Staff are working remotely from different countries;
                  consequently,{' '}
                  <strong>
                    your Personal Information may be transferred from the location in which you reside to other physical
                    locations
                  </strong>{' '}
                  where Staff may need to access it on a need to know basis. It may also be transferred to third parties
                  located in or outside Latin America.
                </p>
                <p>
                  Nexton has implemented appropriate technical and organizational security measures designed to protect
                  the security of any Personal Information processed. We protect your personal information by
                  maintaining physical, electronic and procedural safeguards. We use network safeguards such as
                  firewalls and data encryption and authorize access to personal information only for those who require
                  access to fulfill their work responsibilities.
                </p>
                <p>
                  The risks of transferring data outside of your jurisdiction include the possibility of data breaches
                  and loss. Please note that we cannot guarantee that no event of data breach or loss will ever occur.
                </p>
                <p>
                  In case a data breach incident occurs, we have adequate proceedings in place to contain the effect of
                  the incident, by mitigating the potential damages, seeking to restore any information that might have
                  been lost and taking all necessary measures to prevent it from happening again. In all cases,{' '}
                  <strong>
                    if your Personal Information has been compromised in any way, you will be immediately informed
                  </strong>
                  .
                </p>
                <p>
                  <strong>7. b. Protection of Third-Party Confidential Information</strong>
                </p>
                <p>
                  At Nexton, we recognize and prioritize the importance of safeguarding confidential information. This
                  policy underscores our dedication to safeguarding not only our own confidential information but{' '}
                  <strong>also that of third parties</strong>. This pertains to information subject to{' '}
                  <strong>
                    confidentiality agreements, trade secrets, proprietary knowledge or sensitive information not
                    publicly accessible (the “Third-Party Confidential Information”).
                  </strong>
                </p>
                <p>
                  We require all candidates to exercise{' '}
                  <strong>due diligence and the highest degree of confidentiality</strong> when dealing with Third-Party
                  Confidential Information, particularly relating to current or past employers. We also require
                  candidates to refrain from incorporating this type of information in their resume.
                </p>
                <p>
                  While Nexton is not accountable for Third-Party Confidential Information shared by candidates during
                  the recruiting process, if such information is identified by our staff, it will be treated
                  confidentially and promptly removed from our databases.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>8. Acceptance of these Conditions</strong>
                </Text>
                <p>
                  We assume that all Candidates and Visitors in contact with us have carefully read this Privacy Policy
                  and agree to its content.
                </p>
                <p>
                  We reserve the right to modify, revise, or otherwise amend this Privacy Policy at any time and in any
                  manner; if we do so, however, we will notify you.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>9. Your Privacy Rights</strong>
                </Text>
                <p>Depending on which country’s laws apply, your privacy rights may include:</p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;The right to request access and obtain a copy of your personal information;
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;The right to request rectification of your personal information;
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;The right to request erasure of your personal information;
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;The right to restrict the processing of your personal information;
                </p>
                <p style={{ 'padding-left': ' 20px' }}>&bull;&nbsp;If applicable, the right to data portability;</p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;If applicable, the right to object to the processing of your personal information;
                </p>
                <p style={{ 'padding-left': ' 20px' }}>
                  &bull;&nbsp;Where we rely on your consent for processing of your personal information, as described in
                  this Privacy Policy, you may also have the right to withdraw your consent to such processing.
                </p>
                <p>
                  You can request to exercise one of the rights listed above by contacting us using the contact
                  information within this Privacy Policy.
                </p>
                <p>
                  We will evaluate your request within a period of 30 business days, counted from the day following the
                  receipt of your request. This period may be extended up to 60 days when there are justified causes. We
                  reserve the right not to comply with requests in circumstances where we are not required to do so
                  under applicable law.
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>10. Modifications and revisions</strong>
                </Text>
                <p>
                  We may update this Privacy Policy from time to time. The updated version will be indicated by an
                  updated "Revised" date and the updated version will be effective as soon as it is accessible. If we
                  make material changes to this privacy notice, we may notify you either by prominently posting a notice
                  of such changes or by directly sending you a notification.{' '}
                  <strong>
                    We encourage you to review this privacy notice frequently to be informed of how we are protecting
                    your information.
                  </strong>
                </p>
                <br />
                <Text fontSize={[4, 4, 4]} mt={2}>
                  <strong>11. Contact Information</strong>
                </Text>
                <p>
                  If you have questions or comments about this Privacy Policy, you may email us at
                  privacy@nextonlabs.com or by post to:
                </p>
                <p>Advanced Engineering Services LLC 16192 Coastal Highway Lewes, DE 19958 United States.</p>
              </div>
            </Text>
          </Box>
        </Flex>
      </Layout>
    </>
  );
};

export default PrivacyPolicyPage;
